import React from "react";
import { Link } from "react-router-dom";
import FillBtn from "../../Shared/Buttons/FillBtn";
import { RiArrowGoBackFill } from "react-icons/ri";
import Images from "../../HelperMethods/Constants/ImgConstants";

const NotFound = () => {
  return (
    <div className="border-0 d-flex align-items-center justify-content-center h-100 ">
      <div className="text-center">
        <h1>Page Not Found</h1>
        <img width="50%" src={Images.ERROR_404_IMG} alt="" />
        <p>Sorry, the page you are looking for does not exist.</p>
        <FillBtn
          text={
            <Link
              className="text-white"
              style={{ textDecoration: "none" }}
              to={"/"}
            >
              <RiArrowGoBackFill /> Home
            </Link>
          }
          className="boxShadowOrange"
        />
      </div>
    </div>
  );
};

export default NotFound;
